export default {
  "formT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaire d’inscription"])},
  "formP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificat en santé publique"])},
  "nom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
  "prenom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénoms"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
  "lieu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu de naissance"])},
  "nationalite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationalité"])},
  "residence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays de résidence"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse email"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
  "diplome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diplôme obtenu le plus élevé"])},
  "intitule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intitulé du diplôme le plus élevé"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décrivez brièvement vos expériences professionnelles pertinentes pour le programme (300 mots maximum)"])},
  "sendButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soumettre ma candidature"])},
  "candidatureT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidature envoyée"])},
  "candidatureP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre candidature a été soumise avec succès. Nous vous contacterons pour confirmer votre pré-sélection."])},
  "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitez notre site web"])},
  "buttonbase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postuler au programme"])},
  "slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certifiez-vous en Santé Publique"])},
  "but1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Développez une expertise avérée en santé publique, avec notre programme à distance de certification professionnelle !"])},
  "objectifT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectifs & Compétences visées "])},
  "objectifP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce programme vous permet d’acquérir les compétences interdisciplinaires fondamentales, pour mener efficacement des projets ou politiques de santé, dans une perspective de santé globale et de développement durable. Ce certificat vous prépare à améliorer la santé publique par l’élaboration de projets, l’évaluation de politiques, le changement environnemental et la recherche sur la promotion de la santé."])},
  "objectifT1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Socio-anthropologie"])},
  "objectifP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprendre les influences comportementales et les déterminants sociaux de la santé."])},
  "objectifT2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotion de la santé"])},
  "objectifP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre en œuvre des politiques de prévention et de promotion de la santé."])},
  "objectifT3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Systèmes de santé"])},
  "objectifP3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maîtriser l’organisation et l’administration des systèmes de santé."])},
  "objectifT4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politiques de santé"])},
  "objectifP4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planifier, élaborer et évaluer efficacement des projets ou programmes de santé publique."])},
  "objectifT5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Santé environnementale"])},
  "objectifP5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprendre les liens entre les enjeux environnementaux et la santé, dans une approche de santé globale unique."])},
  "objectifT6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche"])},
  "objectifP6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maîtriser et appliquer les notions épidémiologiques de base à la recherche en santé publique."])},
  "publicsT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publics cibles & pré-requis"])},
  "publicsT1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionnels de la santé"])},
  "publicsP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Médecins, chirurgiens, infirmiers, sages-femmes, pharmaciens, paramédicaux, étudiants en sciences de la santé."])},
  "publicsT2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres publics cibles"])},
  "publicsP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["spécialistes en santé publique, chercheurs en santé, professionnels de la recherche, épidémiologistes, biostatisticiens, analystes de données de santé, personnels d’organisations de santé publique, travailleurs sociaux, gestionnaires de projets de santé, spécialistes en santé communautaire, agents de développement communautaire, professionnels du secteur humanitaire, acteurs de la santé publique, etc."])},
  "moyensT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyens pédagogiques"])},
  "moyensP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce programme est basé sur une méthode pédagogique d’approche par compétences et s’insère parfaitement dans votre agenda sans bousculer votre vie professionnelle ou personnelle."])},
  "formatT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format"])},
  "formatP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les cours sont dispensés à temps partiel, exclusivement à distance, en mode asynchrone (cours pré-enregistrés) et synchrone (classes virtuelles)."])},
  "comiteT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comité"])},
  "comiteP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le comité pédagogique de ce programme est composé de professeurs des universités et d’experts internationaux en santé publique."])},
  "dureeT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée"])},
  "dureeP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["70 heures de cours"])},
  "periodeT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période"])},
  "periodeP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du 1er septembre au 10 octobre 2024"])},
  "evaluationT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation"])},
  "evaluationP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À la fin du programme, l’étudiant(e) est soumis(e) à une épreuve de contrôle de\nconnaissance en ligne au format QCM."])},
  "certificationT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certification"])},
  "certificationP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le programme est sanctionné par un certificat professionnel accrédité en\n« santé publique », qui est délivré aux étudiant(e)s ayant obtenu une note supérieure ou égale à 60% au terme du contrôle des connaissances."])},
  "contenuT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenu du Programme"])},
  "droitInscriptionT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Droit d’inscription"])},
  "droitInscriptionTN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Possibilité de paiements par tranches*"])},
  "droitInscriptionP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les droits d’inscription à la formation s’élèvent à"])},
  "droitInscriptionP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’inscription n’est considérée comme définitive qu’après règlement de ce montant auprès de l’organisme de formation médicale continue REMA, à condition que votre candidature soit acceptée. La date limite pour s’inscrire au programme est le 30 août 2024. Places limitées !"])},
  "placeDisponible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les places disponibles étant limitées pour ce programme de certification, seules les candidatures pertinentes seront retenues. Les candidats sélectionnés seront contactés par nos équipes afin de finaliser leur inscription. Merci.\n"])},
  "produitT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit"])},
  "renseignements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renseignements"])},
  "entrepriseT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise"])},
  "entrepriseP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez-nous"])},
  "entrepriseP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
  "entrepriseP3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrière"])},
  "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["© 2022 REMA. Tous droits réservés."])},
  "confidentialites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confidentialités"])},
  "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions d’utilisation"])}
}